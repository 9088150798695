import '../global.scss';
import React from "react";
import {Box, Grid, makeStyles, createStyles, Typography, Theme, Paper} from "@mui/material";
import {RelicType} from "../types";
import MainCanvas from "../components/MainCanvas";
import {Link} from "react-router-dom";
import {Canvas, useThree} from "@react-three/fiber";
import {OrbitControls} from "@react-three/drei";
import Relic from "../components/Relic";
// import {DefaultXRControllers, VRCanvas} from "@react-three/xr";
import NightTimeSwitch from "../components/NightTimeSwitch";

// todo figure out how to import relics.ts as an array of RelicType or SpaceType in App.tsx so can put types in here properly
type HomePageProps = {
  // relics: [RelicType],
  relics: any,
}
export default function HomePage({relics}: HomePageProps) {
  const [nightTime, setNightTime] = React.useState(false);

  return (
    <div>

      <div className="text4-container">
        <NightTimeSwitch nightTime={nightTime} setNightTime={setNightTime} />
      </div>

      <Grid container spacing={3}>

        {/*<Canvas camera={{ position: [0, 5, 100], fov: 55, near: 1, far: 20000 }}>*/}

        <Canvas className="Canvas" camera={{ position: [0, 5, 100], fov: 11, near: 1, far: 20000 }}>
          {/*<DefaultXRControllers />*/}
          <OrbitControls minDistance={30} maxDistance={700} enablePan={false} enableZoom={true} minPolarAngle={Math.PI/2.1} maxPolarAngle={Math.PI/2.1} />
          {/*<OrbitControls />*/}


          <MainCanvas
            nightTime={nightTime}
            className='canvas'
            loadType={'relic'}
            // cameraPosition={relics[0].meta.cameraPosition}
            imageUrl={relics[0].assetURI}
            useDraco={true}
            vrEnabled={true}
          />
        </Canvas>

      </Grid>

    </div>
  )
}
