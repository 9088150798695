import * as THREE from 'three';
import {Canvas, extend, ReactThreeFiber, useFrame, useLoader, useThree} from "@react-three/fiber";
import {Html, OrbitControls, PerspectiveCamera, Sky, Stars} from "@react-three/drei";
import React, {Suspense, useMemo, useRef} from "react";
import Relic from "./Relic";
import {Water} from "three-stdlib";
import Ship from "./Ship";
import Cannon from "./Cannon";
// import { Water } from "three/examples/jsm/objects/Water.js";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      water: ReactThreeFiber.Object3DNode<Water, typeof Water>
    }
  }
}

let imgs = {
  waterNormals: "https://assets.unegma.net/shared/textures/water-normals.jpeg",
};

extend({ Water });

const Ocean = () => {
  const ref = useRef(null);
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, imgs.waterNormals);
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 1000), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: false,
      // format: gl.encoding // todo fix
    }),
    [waterNormals]
  )
  // @ts-ignore
  useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta*0.1));
  return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />
}

export default function MainCanvas({nightTime, imageUrl, cameraPosition, useDraco=false, vrEnabled=false}:any) {

  return (
    <>
      <ambientLight/>
      <pointLight intensity={1} position={[0, 0, 0]}/>
      {/*<directionalLight position={[10, 10, 5]} intensity={2} />*/}
      {/*<directionalLight position={[-10, -10, -5]} intensity={1} />*/}
      {/*<PerspectiveCamera position={cameraPosition} makeDefault/>*/}

      <pointLight position={[100, 100, 100]} />
      <pointLight position={[-100, -100, -100]} />

      <Suspense fallback={<Html className={nightTime ? 'white' : 'black'}>loading..</Html>}>
        {/*<Relic vrEnabled={vrEnabled} useDraco={useDraco} imageUrl={imageUrl}/>*/}
        <Ship/>
        <Cannon/>
      </Suspense>

      <Suspense fallback={<Html className={nightTime ? 'white' : 'black'}>loading..</Html>}>
        <Ocean />
      </Suspense>

      { !nightTime && (
        <>
          {/*<Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />*/}
          <Sky sunPosition={[500, 150, -1000]} turbidity={0.1} />
        </>
      )}

      { nightTime && (
        <>
          <color attach="background" args={['#000']}/>
          <Stars
            radius={100} // Radius of the inner sphere (default=100)
            depth={50} // Depth of area where stars should fit (default=50)
            count={5000} // Amount of stars (default=5000)
            factor={4} // Size factor (default=4)
            saturation={0} // Saturation 0-1 (default=0)
            fade // Faded dots (default=false)
          />
        </>
      )}

    </>
  )
}
