export const relics = [
  {
    "key": "lion",
    "type": "relic",
    "details": {
      "name": "Lion",
      "description": "This is a Lion",
    },
    "meta": {
      "cameraPosition": [-1, 1.5, -1],
    },
    "assetURI": "https://assets.unegma.net/colcam.com.co/trawler-compressed.gltf",
    "audioURI": ""
  }
];
