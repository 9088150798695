import React from 'react';
import {
  Switch, Route, Link, Redirect
} from "react-router-dom";
import './App.css';
import HomePage from "./pages/HomePage";
import { createStyles, Theme, makeStyles, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import {IconButton} from "@mui/material";
import {Menu as MenuIcon, ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon} from "@mui/icons-material";
import {RelicType} from "./types";
import {relics} from './helpers/relics'; // todo figure out how to import as an array of RelicType
// import {appStyles} from './styles/appStyles';

function App() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    console.log('here1')
    setOpen(!open);
  };

  return (
    <div >
      <CssBaseline />

      <main>

        <Switch>
          <Route
            exact
            key={'home'}
            path="/"
            children={
              <HomePage
                relics={relics}
              />
            }
          />

          {/*{ relics.map((relic: any) => (*/}
          {/*  <Route*/}
          {/*    exact*/}
          {/*    key={relic.key}*/}
          {/*    path={`/relics/${relic.key}`}*/}
          {/*    children={<RelicsPage relic={relic} />}*/}
          {/*  />*/}
          {/*))}*/}

          <Redirect to="/" />
        </Switch>
      </main>

    </div>
  );
}

export default App;
