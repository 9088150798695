/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

const assetURI = 'https://assets.unegma.net/colcam.com.co/ship-transformed.glb';

type GLTFResult = GLTF & {
  nodes: {
    ship: THREE.Mesh
  }
  materials: {
    Material_0: THREE.MeshStandardMaterial
  }
}

export default function Ship({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  useFrame((state, delta) => {
    // @ts-ignore
    group.current.position.y = 0.9 + Math.sin(state.clock.elapsedTime) * 0.1
    // @ts-ignore
    group.current.rotation.y += delta * 0.01
    // ref.current.rotation.y = ref.current.rotation.z += delta * 0.01
  })

  // @ts-ignore
  const { nodes, materials } = useGLTF(assetURI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  console.log(materials)
  return (
    <group scale={8} ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.ship.geometry} material={materials.Material_0} position={[0, 0.67, 0]} />
    </group>
  )
}

useGLTF.preload(assetURI)
