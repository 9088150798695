import React from "react";
import {Brightness5, Brightness2, MusicNoteOutlined, MusicOffOutlined, ViewInAr} from '@mui/icons-material';

const NightTimeSwitch = ({nightTime, setNightTime}: any) => {
  const toggle = () => setNightTime(!nightTime);

  return (
    <>
      {/*todo move to separate file*/}
      {/*todo add check for if safari*/}
      <div className="ar-button">
        <a href="https://assets.unegma.net/colcam.com.co/trawler.usdz" rel="ar">
          <ViewInAr className={nightTime ? "white" : "black"}/>
        </a>
      </div>

      {!nightTime && (
        <Brightness5 className="pointer" style={{color: "black"}} onClick={toggle} />
      )}
      {nightTime && (
        <Brightness2 className="pointer" style={{color: "white"}} onClick={toggle} />
      )}
    </>
  );
};

export default NightTimeSwitch;
